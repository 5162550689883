import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAxios } from '../contexts/axios';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import Logo from './shared/images/Logo';
import LoadingSpinner from './shared/loadingSpinner';
import clsx from 'clsx';
import FeaturesPanel from './featuresPanel';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';

interface ISignupForm {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  companyName?: string;
}

function Signup() {
  const [inputs, setInputs] = useState<ISignupForm>({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [disabled, setDisabled] = useState<boolean | null | undefined>(null);
  const [showInvalid, setShowInvalid] = useState<boolean>(false);

  const navigate = useNavigate();
  const { axios, loading } = useAxios();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const toggleShowPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onInvalid = (event: FormEvent, inputErrorMessage: string) => {
    const target = event.target as HTMLInputElement;
    // !Note - could override HTML5 validation to add custom error messages instead of setting customValidity to inputErrorMessage
    target.setCustomValidity(inputErrorMessage);
    setShowInvalid(true);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setShowInvalid(false);
    console.log(inputs); // for DEV seeing object/data
    axios
      .post('/recruiters/register', {
        user: {
          email: inputs.email,
          first_name: inputs.firstName,
          last_name: inputs.lastName,
          password_confirmation: inputs.password,
          password: inputs.password,
        },
        organization: {
          name: inputs.companyName,
        },
      })
      .then(() => {
        setDisabled(true);
        Mixpanel.track(MixpanelEventType.SIGNUP_SUCCESS);
        (document.getElementById('signupForm') as HTMLFormElement).reset();
        //!Note - user shouldn't be signed in context here but once email is verified in confirmEmailVerified component?
        /*   auth.signin(resp.data); */
        setTimeout(() => {
          setDisabled(undefined);
          navigate('/verify', { replace: true });
        }, 3000);
      })
      .catch((error) => {
        setDisabled(false);
        Mixpanel.track(MixpanelEventType.SIGNUP_FAILED, {
          status: error.response?.status,
        });
        const status = error.response ? error.response.status : error.message;
        // user already exists
        switch (status) {
          case 422:
            alert('Error: a user with that email address already exists.');
            break;
          // server error
          case 500:
            alert(`Error: ${error.message}`);
            break;
          // default general error
          default:
            alert('Error: registration invalid or failed, please try again');
        }
      });
  };

  return (
    <div className="flex h-screen">
      <FeaturesPanel className="hidden lg:flex flex-1 w-0" />

      {/** Showcase And Form Split  */}
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-56">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          {/* Header */}
          <div>
            {/** Logo */}
            <Logo className="h-10 block mt-[8rem] md:mt-0 mb-3 md:mb-0 text-primary md:invisible" />
            <h2 className="text-3xl md:-mt-10 font-bold tracking-tight text-gray-900">
              Sign up for a 30-day Free Trial - Includes 1 Free Job!
            </h2>
            <p className="mt-2 text-sm text-gray-600">Already have a Prosper Online account?&nbsp;</p>
            <span>
              <Link className="text-primary text-sm no-underline hover:underline font-medium" to={'/login'}>
                Sign in
              </Link>
            </span>
          </div>
          <div className="mt-4">
            <div>
              {/** Sign Up Form */}
              <form id="signupForm" onSubmit={handleSubmit} className="space-y-6">
                {/** Company Email Address */}
                <div>
                  <label htmlFor="user_email">Company email address*</label>
                  <div className="mt-1">
                    <input
                      id="user_email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                      onInvalid={(e) => onInvalid(e, 'invalid email')}
                      className={clsx(showInvalid && 'invalid')}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/** First Name & Last Name */}
                <div className="flex flex-col md:flex-row">
                  {/** First Name */}
                  <div className="md:pr-5 md:mb-0 mb-4">
                    <label htmlFor="user_first_name">First name*</label>
                    <div className="mt-1">
                      <input
                        id="user_first_name"
                        name="firstName"
                        type="text"
                        minLength={1}
                        maxLength={50}
                        required
                        onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                        onInvalid={(e) => onInvalid(e, 'invalid first name')}
                        /** regex
                         *  no white space at beginning or end */
                        pattern={'^(?! )[A-Za-z ]*(?<! )$'}
                        className={clsx(showInvalid && 'invalid')}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/** Last Name */}
                  <div className="">
                    <label htmlFor="user_last_name">Last name*</label>
                    <div className="mt-1">
                      <input
                        id="user_last_name"
                        name="lastName"
                        type="text"
                        minLength={1}
                        maxLength={50}
                        onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                        onInvalid={(e) => onInvalid(e, 'invalid last name')}
                        /** regex
                         *  no white space at beginning or end */
                        pattern={'^(?! )[A-Za-z ]*(?<! )$'}
                        required
                        className={clsx(showInvalid && 'invalid')}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                {/** Company Name  */}
                <div>
                  <label htmlFor="user_company_name">Company name*</label>
                  <div className="mt-1">
                    <input
                      id="user_company_name"
                      name="companyName"
                      minLength={3}
                      maxLength={50}
                      type="text"
                      required
                      autoComplete="off"
                      onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                      onInvalid={(e) => onInvalid(e, 'invalid company name')}
                      /** regex
                       *  no white space at beginning or end */
                      pattern={'^(?! )[A-Za-z ]*(?<! )$'}
                      className={clsx(showInvalid && 'invalid')}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/** Password */}
                <div>
                  <label htmlFor="user_password">Password*</label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      id="user_password"
                      name="password"
                      type={passwordShown ? 'text' : 'password'}
                      onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                      onInvalid={(e) =>
                        onInvalid(
                          e,
                          'must include 1 uppercase, 1 lowercase, 1 number, 1 special character, and minimum length of 10 characters'
                        )
                      }
                      autoComplete="current-password"
                      /** password regex
                       *  must include 1 uppercase, 1 lowercase, 1 number, 1 special character, and min length 8 char */
                      pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$"
                      required
                      className={clsx(showInvalid && 'invalid')}
                      onChange={handleChange}
                    />
                    <>
                      <button
                        type="button"
                        onClick={toggleShowPassword}
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                        {passwordShown ? (
                          <EyeSlashIcon className="w-5 h-5 text-black" aria-hidden="true" />
                        ) : (
                          <EyeIcon className="w-5 h-5 text-black" aria-hidden="true" />
                        )}
                      </button>
                    </>
                  </div>
                </div>

                {/** Terms And Policy Fine Print */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="user_accepts_policy_and_terms"
                      name="isAcceptingPolicyAndTerms"
                      onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                      onInvalid={(e) => onInvalid(e, 'please check this box if you wish to proceed')}
                      className={clsx(
                        'block appearance-none hover:cursor-pointer rounded-md border border-gray-300 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm',
                        showInvalid && 'invalid'
                      )}
                    />
                    <label className="block text-sm ml-3 text-gray-600" htmlFor="user_accept_policy_and_terms">
                      By clicking Start Free Trial, I agree that I have read and accepted the Terms of Use and Privacy
                      Policy.
                    </label>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={disabled!}
                    className={clsx('btn btn-primary w-full', {
                      'disabled:opacity-80 disabled:cursor-not-allowed': !loading || disabled === undefined,
                    })}
                  >
                    {!loading && disabled ? (
                      <LoadingSpinner className="mr-2 w-4 h-4 text-white fill-gray" />
                    ) : (
                      'Start Free Trial'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="mt-8">
            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm"></div>
            </div>

            {/** Privacy Policy & Terms Of Service fine print */}
            <div className="mt-6 md:mb-0 mb-5">
              <p className=" text-sm text-gray-600">
                Protected by reCAPTCHA and subject to the Prosper{' '}
                <span className="text-primary">Prosper Privacy Policy</span> and{' '}
                <span className="text-primary">Terms of Service</span>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
