import React from 'react';

export default function NewJobGeneral() {
  return (
    <form className="h-full w-full bg-white flex flex-col gap-5 rounded-lg p-8">
      <div className="flex flex-col md:flex-row justify-between">
        <h1 className="font-black text-xl">Create a new job post</h1>
        {/** Preview Button */}
        <button
          type="button"
          className="px-10 py-1 text-primary font-semibold border border-primary rounded-lg hover:bg-primary hover:text-white"
        >
          Preview
        </button>
      </div>
      <div className="flex flex-col justify-start">
        <p className="font-semibold text-sm">General Information</p>
      </div>
      <div className="flex flex-col justify-start text-sm">
        <p className="mb-1">Job title</p>
        <input type="text" />
      </div>
      <div className=" flex flex-col justify-start text-sm">
        <label htmlFor="jobLocation">Job location</label>
        <select id="jobLocation" name="jobLocation" required>
          <option className="text-subtitle" value="cleveland">
            Cleveland, OH
          </option>
          <option className="text-subtitle" value="new_york">
            New York, NY
          </option>
          <option className="text-subtitle" value="los_angeles">
            Los Angeles, CA
          </option>
        </select>
      </div>
      <div className="flex flex-col justify-start text-sm">
        <p className="mb-1">Start date</p>
        <input type="text" />
      </div>
      <div className="flex flex-col gap-2 justify-start text-sm">
        <label htmlFor="jobDescription">Job description</label>
        <p className="text-[#6B6B6B] text-xs ">
          Describe the responsibilities, experience and requirements needed for this job
        </p>
        <p className="text-[#0F799A] font-medium">Upload PDF or DOCX</p>
        <textarea className="h-64" id="jobDescription" name="jobDescription" required />
      </div>
      {/** 'Back' & 'Save & Continue' Buttons */}
      <div className="flex flex-col gap-4 lg:flex-row w-full">
        <button className="btn border-1 border-[#858585] text-[#858585] flex-grow">Back</button>
        <button type="submit" className="btn btn-primary flex-grow">
          Save & Continue
        </button>
      </div>
    </form>
  );
}
