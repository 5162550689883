import mixpanel, { Dict } from 'mixpanel-browser';
mixpanel.init('0d16ad59ae3c4c3dbc20b66834e53392', {
  cross_subdomain_cookie: false,
});

const prod = process.env.NODE_ENV === 'production';

const actions = {
  identify: (id: string) => {
    if (prod) mixpanel.identify(id);
  },
  track: (name: MixpanelEventType, props: Dict = {}) => {
    if (prod) mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      if (prod) mixpanel.people.set(props);
    },
  },
  reset: () => {
    if (prod) mixpanel.reset();
  },
};

export const Mixpanel = actions;

export enum MixpanelEventType {
  // Signup
  SIGNUP_SUCCESS = 'Signup.Success',
  SIGNUP_FAILED = 'Signup.Failed',

  // Login
  LOGIN_SUCCESS = 'Login.Success',
  LOGIN_FAILED = 'Login.Failed',

  // Confirm Email
  CONFIRM_EMAIL_SUCCESS = 'ConfirmEmail.Success',
  CONFIRM_EMAIL_INVALID = 'ConfirmEmail.Invalid',

  // Create Company Profile
  CREATE_COMPANY_PROFILE_SUCCESS = 'CreateCompanyProfile.Success',
  CREATE_COMPANY_PROFILE_FAILED = 'CreateCompanyProfile.Failed',
  CREATE_COMPANY_PROFILE_INDUSTRIES_LOADED = 'CreateCompanyProfile.Industries.Loaded',
  CREATE_COMPANY_PROFILE_INDUSTRIES_FAILED = 'CreateCompanyProfile.Industries.Failed',

  // Billing
  BILLING_INFO_SUCCESS = 'Billing.Info.Success',
  BILLING_INFO_FAILED = 'Billing.Info.Failed',
  BILLING_PLAN_SUCCESS = 'Billing.Plan.Success',
  BILLING_PLAN_FAILED = 'Billing.Plan.Failed',
  BILLING_PAYMENT_SUCCESS = 'Billing.Payment.Success',
  BILLING_PAYMENT_FAILED = 'Billing.Payment.Failed',
  BILLING_STRIPE_PAYMENT_SUCCESS = 'Billing.StripePayment.Success',
  BILLING_STRIPE_PAYMENT_FAILED = 'Billing.StripePayment.Failed',

  // Forgot Password
  FORGOT_PASSWORD_SUBMIT = 'ForgotPassword.Submit',

  // New Password
  NEW_PASSWORD_SUCCESS = 'NewPassword.Success',
  NEW_PASSWORD_FAILED = 'NewPassword.Failed',

  LOCATION_CHANGE = 'Location.Change',
}
