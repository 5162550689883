import React, { useState } from 'react';
import PasswordResetNewPassword from './passwordResetNewPassword';
import PasswordResetSuccesful from './passwordResetSuccesful';
import FeaturesPanel from './featuresPanel';

function NewPassword() {
  const [completed, setCompleted] = useState(false);
  return (
    <div>
      <div className="flex h-screen flex-row w-full">
        <FeaturesPanel className="hidden lg:flex flex-1 w-5/12" />
        <div className="lg:w-7/12 flex bg-lightblue">
          {completed ? <PasswordResetSuccesful /> : <PasswordResetNewPassword setCompleted={setCompleted} />}
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
