import React from 'react';
import { useNavigate } from 'react-router-dom';

function CreateCompanyProfilePerson() {
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate('/onboard/company');
  };

  return (
    <section>
      <form className="flex w-screen h-4/5 justify-center items-center bg-lightblue p-4">
        <div className="flex w-5/12 h-8/12 bg-white flex-col rounded-lg mt-8">
          <div className="p-4 flex flex-col justify-start">
            <h1 className="font-black text-xl mb-2">Create your company profile</h1>
          </div>
          <div className="p-4 flex flex-col justify-start">
            <p className="font-semibold text-sm mb-2">Your information</p>
          </div>
          <div className="pb-4 pl-4 pr-4 flex flex-col justify-start text-sm">
            <p className="font-medium mb-2">Phone number</p>
            <input type="tel" placeholder="Phone number" />
          </div>
          <div className="pb-4 pl-4 pr-4 flex flex-col justify-start text-sm">
            <p className="mb-2">Current title</p>
            <input type="text" placeholder="Current title" />
          </div>
          <div className="p-4 flex flex-col justify-start text-sm">
            <p className="mb-2">Your profile picture</p>
            <div className="flex justify-center items-center w-full">
              <label className="flex justify-center items-center w-full h-2/3 bg-gray-200 rounded-lg border-subtitle cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex justify-center items-center pt-5 pb-6">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Upload your Documents</span>
                  </p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
          </div>
          <div className="flex justify-center items-center w-full pb-5">
            <button type="submit" className="btn btn-primary" onClick={navigateToProfile}>
              Save & Continue
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default CreateCompanyProfilePerson;
