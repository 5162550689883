import React, { useState, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import lock from '../assets/passwordResetIcon.svg';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';

type IPasswordReset = {
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function PasswordResetNewPassword({ setCompleted }: IPasswordReset) {
  const [password, setPassword] = useState('');
  const [passwordC, setPasswordC] = useState('');
  const [showInvalid, setShowInvalid] = useState<boolean>(false);

  const handleSubmit = () => {
    if (password && passwordC) {
      const lengthP = password.length;
      const lengthPC = password.length;
      if (lengthP === lengthPC) {
        setCompleted(true);
      }
    }
  };

  const onInvalid = (event: FormEvent, inputErrorMessage: string) => {
    const target = event.target as HTMLInputElement;
    target.setCustomValidity(inputErrorMessage);
    setShowInvalid(true);
  };

  return (
    <div className="flex w-screen items-center justify-center h-screen flex-row">
      <div className="h-screen flex w-9/12 justify-center items-center p-4 bg-lightblue">
        <div className="flex w-8/12 flex-col">
          <div className="flex p-4 items-center justify-center">
            <img src={lock} alt="lock" />
          </div>
          <div className="flex p-4 items-center justify-center">
            <h1 className="text-3xl font-black">Set a new password</h1>
          </div>
          <div className="flex p-4 items-center justify-center">
            <h1 className="text-sm text-subtitle flex text-center">Please enter your new password below.</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex p-4 items-left justify-center flex-col">
              <input
                required
                type="password"
                value={password}
                pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{10,}$"
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
                onInvalid={(e) =>
                  onInvalid(
                    e,
                    'must include 1 uppercase, 1 lowercase, 1 number, 1 special character, and minimum length of 10 characters'
                  )
                }
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <p className="text-sm text-subtitle p-1">Must be at least 8 characters long.</p>
            </div>
            <div className="flex p-4 items-left justify-center flex-col">
              <input
                required
                type="password"
                value={passwordC}
                placeholder="Confirm password"
                onChange={(e) => {
                  setPasswordC(e.target.value);
                }}
              />
            </div>
            <div className="flex w-full p-4 items-center justify-center flex-col">
              <div className="p-4 items-center justify-center">
                <button type="submit" className="btn btn-primary">
                  Reset password
                </button>
              </div>
              <div className="flex items-center justify-center">
                <h1>Didn&apos;t receive an email?</h1>
                <Link to="/passwordreset" className="p-2 text-sm text-primary flex flex-row items-center">
                  Click to re-send
                </Link>
              </div>
              <Link to="/login" className="p-2 text-sm text-subtitle flex flex-row items-center">
                <ArrowSmallLeftIcon className="h-4" /> Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
