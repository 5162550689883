import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../contexts/axios';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';

type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
interface FormFields {
  description: string;
  headcount: string;
  industry_id: string;
  logo: File;
}

function CreateCompanyProfile() {
  const [industries, setIndustries] = useState<{ id: string; name: string }[]>([]);
  const [company, setCompany] = useState<Partial<FormFields>>({
    headcount: 'ten',
  });
  const { axios } = useAxios();
  const navigate = useNavigate();

  const navigateToPlans = () => {
    navigate('/onboard/plans');
  };

  useEffect(() => {
    axios.get('/recruiters/industries').then(
      (res) => {
        setIndustries(res.data);
        Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_INDUSTRIES_LOADED);
      },
      (err) => {
        console.log(err);
        Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_INDUSTRIES_FAILED);
      }
    );
  }, []);

  function handleChange(e: ChangeEvent<InputElement>): void {
    const value = e.target.value;
    setCompany({
      ...company,
      [e.target.name]: value,
    });
  }

  function handleLogoChange(e: ChangeEvent<HTMLInputElement>): void {
    const files = e.target.files;
    if (files) {
      setCompany({
        ...company,
        logo: files[0],
      });
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (company) {
      if (company.industry_id === undefined || company.industry_id === null) {
        return console.error('input is not valid');
      } else if (company.description === undefined || company.description === null) {
        return console.error('input is not valid');
      } else if (company.headcount === undefined || company.headcount === null) {
        return console.error('input is not valid');
      } else {
        const data = new FormData();
        data.append('company[description]', company.description);
        data.append('company[headcount]', company.headcount);
        data.append('company[industry_id]', company.industry_id);
        if (company.logo) data.append('company[logo]', company.logo);
        axios
          .put('/recruiters/company', data)
          .then(() => {
            Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_SUCCESS);
          })
          .catch((err) => {
            Mixpanel.track(MixpanelEventType.CREATE_COMPANY_PROFILE_FAILED);
            console.log('failed', err);
          });
        navigateToPlans();
      }
    }
  };

  return (
    <section>
      <form
        className="flex w-screen h-4/5 justify-center items-center bg-lightblue pb-4 pr-4 pl-4"
        onSubmit={handleSubmit}
      >
        <div className="flex w-5/12 h-8/12 bg-white flex-col rounded-lg mt-8">
          <div className="pt-4 pl-4 flex flex-col justify-start">
            <h1 className="font-black text-xl mb-2">Create your company profile</h1>
          </div>
          <div className="pt-2 pl-4 flex flex-col justify-start">
            <p className="font-semibold text-sm mb-2">Company information</p>
          </div>
          <div className="p-4 flex flex-col justify-start text-sm">
            <label htmlFor="industry_id">Company industry</label>
            <select
              name="industry_id"
              id="industry_id"
              defaultValue=""
              required
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option value="" disabled>
                Industry
              </option>
              {industries.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="p-4 flex flex-col justify-start text-sm">
            <label htmlFor="description">Tell us more about the company</label>
            <textarea
              id="description"
              name="description"
              placeholder="About the company"
              required
              value={company.description}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="p-4 flex flex-col justify-start text-sm">
            <label htmlFor="headcount">Number of employees</label>
            <select
              id="headcount"
              name="headcount"
              required
              value={company.headcount}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option className="text-subtitle" value="ten">
                1-10
              </option>
              <option className="text-subtitle" value="fifty">
                11-50
              </option>
              <option className="text-subtitle" value="two_hundred">
                51-200
              </option>
              <option className="text-subtitle" value="five_hundred">
                201-500
              </option>
              <option className="text-subtitle" value="one_thousand">
                501-1,000
              </option>
              <option className="text-subtitle" value="five_thousand">
                1,001-5,000
              </option>
              <option className="text-subtitle" value="ten_thousand">
                5,001-10,000
              </option>
              <option className="text-subtitle" value="max">
                10,000+
              </option>
            </select>
          </div>
          <div className="p-4 flex flex-col justify-start text-sm">
            <p className="mb-2">Company logo</p>
            {/* <input className="border-2 rounded" type="file" placeholder="Company logo"/> */}
            <div className="flex justify-center items-center w-full">
              <label className="flex justify-center items-center w-full h-2/3 bg-gray-200 rounded-lg border-subtitle cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex justify-center items-center pt-5 pb-6">
                  <p className="flex justify-center items-center text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Upload your Documents</span>
                  </p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={(e) => handleLogoChange(e)} />
              </label>
            </div>
          </div>
          <div className="flex justify-center items-center w-full pb-5">
            <button type="submit" value="Submit" className="btn btn-primary">
              Save & Continue
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default CreateCompanyProfile;
