import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import JobDetails from './JobDetails';
import JobMatches from './JobMatches';
import axios from 'axios';
import { Job } from '../../models/job';
import { ChevronRightIcon } from '@heroicons/react/20/solid/';
import Loader from '../Loader';

export default function JobPage() {
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState(true);
  const [job, setJob] = useState<Job>();
  const { id } = useParams();

  useEffect(() => {
    axios.get(`/job_postings/${id}`).then(
      (res) => {
        setJob(res.data);
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  }, []);

  return loading ? (
    <Loader className="absolute flex top-1/2 left-1/2" />
  ) : (
    <div>
      <div className="font-semibold text-xl">
        <Link className="hover:border-b-2 hover:border-black" to="/jobs">
          Jobs
        </Link>{' '}
        <ChevronRightIcon className="h-8 fill-primary inline" />
        {job === undefined ? 'No Job Found' : job?.title}
      </div>

      <div className="flex gap-10 mt-7">
        <div className="flex gap-5 items-center bg-white p-2 pr-9 w-fit rounded-xl">
          <div className="bg-porange/10 text-2xl font-semibold p-4 rounded-xl text-center min-w-[5rem]">125</div>
          <div className="flex flex-col">
            <span className="font-semibold text-lg">New Matches</span>
            <span className="text-sm">Salary the last month</span>
          </div>
        </div>

        <div className="flex gap-5 items-center bg-white p-2 pr-9 w-fit rounded-xl">
          <div className="bg-[#F12626]/10 text-2xl font-semibold p-4 rounded-xl text-center min-w-[5rem]">12</div>
          <div className="flex flex-col">
            <span className="font-semibold text-lg">Expiring Matches</span>
            <span className="text-sm">Salary the last month</span>
          </div>
        </div>

        <div className="flex gap-5 items-center bg-white p-2 pr-9 w-fit rounded-xl">
          <div className="bg-[#A332FF]/10 text-2xl font-semibold p-4 rounded-xl text-center min-w-[5rem]">6</div>
          <div className="flex flex-col">
            <span className="font-semibold text-lg">Interviews</span>
            <span className="text-sm">Salary the last month</span>
          </div>
        </div>
      </div>

      <div className="bg-white mt-7 rounded-xl">
        <div className="flex justify-around text-center pt-6">
          <span
            className={clsx(
              'text-xl font-semibold border-b-2 w-full pb-4 cursor-pointer',
              matches ? 'text-primary border-primary' : 'text-gray-400 border-gray-400'
            )}
            onClick={() => setMatches(true)}
          >
            Matches
          </span>
          <span
            className={clsx(
              'text-xl font-semibold border-b-2 w-full pb-4 cursor-pointer',
              matches ? 'text-gray-400 border-gray-400' : 'text-primary border-primary'
            )}
            onClick={() => setMatches(false)}
          >
            Job Details
          </span>
        </div>

        <div className="p-8">
          {job === undefined ? (
            <span className="flex justify-center">No Job Found</span>
          ) : matches ? (
            <JobMatches job={job} />
          ) : (
            <JobDetails job={job} />
          )}
        </div>
      </div>
    </div>
  );
}
