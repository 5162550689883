import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import Calendar from './calendar';
import Candidates from './candidates';
import { UserProvider } from '../contexts/user';
import Dashboard from './dashboard';
import RequireAuth from './require-auth';
import Jobs from './jobs/Jobs';
import CreateJob from './jobs/CreateJob';
import JobPage from './jobs/JobPage';
import Layout from './layout';
import Login from './login';
import Settings from './settings';
import Statistics from './statistics';
import Users from './users';
import Plans from './plans';
import Billing from './billing';
import { AxiosInstanceProvider } from '../contexts/axios';
import Signup from './signup';
import ResetPassword from './resetPassword';
import NewPassword from './newPassword';
import CreateCompanyProfilePerson from './createCompanyProfilePerson';
import CreateCompanyProfile from './createCompanyProfile';
import Onboarding from './onboarding';
import VerifyEmail from './verifyEmail';
import ConfirmEmailVerified from './confirmEmailVerified';
import PageTracker from './pageTracker';

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <AxiosInstanceProvider>
          <PageTracker>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/verify" element={<VerifyEmail />} />
              <Route path="/confirm/:token" element={<ConfirmEmailVerified />} />
              <Route path="/passwordreset" element={<ResetPassword />} />
              <Route path="/newpassword" element={<NewPassword />} />
              <Route path="/onboard" element={<Onboarding />}>
                <Route index path="user" element={<CreateCompanyProfilePerson />} />
                <Route path="company" element={<CreateCompanyProfile />} />
                <Route path="plans" element={<Plans />} />
                <Route path="billing" element={<Billing />} />
              </Route>
              <Route path="/" element={<Layout />}>
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="calendar"
                  element={
                    <RequireAuth>
                      <Calendar />
                    </RequireAuth>
                  }
                />
                <Route
                  path="stats"
                  element={
                    <RequireAuth>
                      <Statistics />
                    </RequireAuth>
                  }
                />
                <Route
                  path="jobs"
                  element={
                    <RequireAuth>
                      <Jobs />
                    </RequireAuth>
                  }
                />
                <Route
                  path="jobs/create"
                  element={
                    <RequireAuth>
                      <CreateJob />
                    </RequireAuth>
                  }
                />
                <Route
                  path="jobs/:id"
                  element={
                    <RequireAuth>
                      <JobPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="candidates"
                  element={
                    <RequireAuth>
                      <Candidates />
                    </RequireAuth>
                  }
                />
                <Route
                  path="users"
                  element={
                    <RequireAuth>
                      <Users />
                    </RequireAuth>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <RequireAuth>
                      <Settings />
                    </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </PageTracker>
        </AxiosInstanceProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
