import React, { useState } from 'react';
import clsx from 'clsx';

import {
  ListBulletIcon,
  Squares2X2Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/solid/';
import { Job } from '../../models/job';

interface IJobsMatchesProps {
  job?: Job;
}

export default function JobMatches({ job }: IJobsMatchesProps) {
  const [isListView, setIsListView] = useState(true);

  return (
    <div className="px-8">
      {/* Top Macthes and Sort */}
      <div className="flex items-center">
        <div className="mr-auto flex gap-3 items-center">
          <span className="font-semibold">Top Matches</span>
          <span className="text-sm text-primary cursor-pointer">View All</span>
        </div>

        <div className="ml-auto flex gap-3 items-center">
          <Squares2X2Icon
            className={clsx('cursor-pointer h-5 w-5', isListView ? 'fill-subtitle' : 'fill-primary')}
            onClick={() => setIsListView((prev) => !prev)}
          />
          <ListBulletIcon
            className={clsx('cursor-pointer h-5 w-5', isListView ? 'fill-primary' : 'fill-subtitle')}
            onClick={() => setIsListView((prev) => !prev)}
          />
          <label className="text-subtitle" htmlFor="sort">
            Sort By:
          </label>
          <select className="border-none rounded-md py-1" name="sort" id="sort">
            <option value="newest">Newest</option>
            <option value="match">Match</option>
            <option value="status">Status</option>
          </select>
        </div>
      </div>

      {/* Matches Table */}
      <table className=" w-full mt-7 [&_tr_td]:p-3 ">
        <thead className="bg-subtitle/10 [&_tr_td]:py-6">
          <tr>
            <td className="text-lg font-medium">Date Applied</td>
            <td className="text-lg font-medium">Candidate</td>
            <td className="text-lg font-medium">Location</td>
            <td className="text-lg font-medium">Match %</td>
            <td className="text-lg font-medium" colSpan={2}>
              Status
            </td>
          </tr>
        </thead>

        <tbody className="">
          <tr className="border-b-2 border-subtitle/10">
            <td>June 1, 2020, 08:22 AM</td>
            <td className="flex items-center gap-3">
              <img className="rounded-md" src="https://via.placeholder.com/30" alt="" />
              <span>A. Salaam</span>
            </td>
            <td>Dallas,TX (CST)</td>
            <td>85%</td>
            <td>
              <button className="w-32 h-10 border-[1px] border-pgreen px-4 py-2 rounded-full text-pgreen font-semibold">
                New Match
              </button>
            </td>
            <td className="cursor-pointer">. . .</td>
          </tr>

          <tr className="border-b-2 border-subtitle/10">
            <td>June 1, 2020, 08:22 AM</td>
            <td className="flex items-center gap-3">
              <img className="rounded-md" src="https://via.placeholder.com/30" alt="" />
              <span>A. Salaam</span>
            </td>
            <td>Dallas,TX (CST)</td>
            <td>75%</td>
            <td>
              <button className="w-32 h-10 border-[1px] border-pgreen px-4 py-2 rounded-full text-pgreen font-semibold">
                New Match
              </button>
            </td>
            <td className="cursor-pointer">. . .</td>
          </tr>

          <tr className="border-b-2 border-subtitle/10">
            <td>June 1, 2020, 08:22 AM</td>
            <td className="flex items-center gap-3">
              <img className="rounded-md" src="https://via.placeholder.com/30" alt="" />
              <span>A. Salaam</span>
            </td>
            <td>Dallas,TX (CST)</td>
            <td>95%</td>
            <td>
              <button className="w-32 h-10 border-[1px] border-primary px-4 py-2 rounded-full text-primary font-semibold">
                Scheduled
              </button>
            </td>
            <td className="cursor-pointer">. . .</td>
          </tr>

          <tr className="border-b-2 border-subtitle/10">
            <td>June 1, 2020, 08:22 AM</td>
            <td className="flex items-center gap-3">
              <img className="rounded-md" src="https://via.placeholder.com/30" alt="" />
              <span>A. Salaam</span>
            </td>
            <td>Dallas,TX (CST)</td>
            <td>88%</td>
            <td>
              <button className="w-32 h-10 border-[1px] border-primary px-4 py-2 rounded-full text-primary font-semibold">
                Scheduled
              </button>
            </td>
            <td className="cursor-pointer">. . .</td>
          </tr>

          <tr className="border-b-2 border-subtitle/10">
            <td>June 1, 2020, 08:22 AM</td>
            <td className="flex items-center gap-3">
              <img className="rounded-md" src="https://via.placeholder.com/30" alt="" />
              <span>A. Salaam</span>
            </td>
            <td>Dallas,TX (CST)</td>
            <td>29%</td>
            <td>
              <button className="w-32 h-10 bg-primary px-4 py-2 rounded-full text-white font-semibold">
                Interviewed
              </button>
            </td>
            <td className="cursor-pointer">. . .</td>
          </tr>
        </tbody>
      </table>

      {/* Page Box */}
      <div className="flex justify-center mt-7">
        <div className="flex w-80 justify-evenly border-2 border-subtitle rounded-xl">
          <ChevronDoubleLeftIcon className="cursor-pointer h-11 p-3 border-subtitle border-r-[1px] fill-subtitle" />

          <ChevronLeftIcon className="cursor-pointer h-11 p-3 border-subtitle border-x-[1px] fill-subtitle" />

          <select className="h-11  text-primary border-y-0 border-x-[1px]" name="" id="">
            <option value="">1-10 of 1269</option>
          </select>

          <ChevronRightIcon className="cursor-pointer h-11 p-3 border-subtitle border-x-[1px] fill-subtitle " />

          <ChevronDoubleRightIcon className="cursor-pointer h-11 p-3 border-subtitle border-l-[1px] fill-subtitle" />
        </div>
      </div>
    </div>
  );
}
