import React from 'react';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import Badge from '../shared/badge';
import { Job } from '../../models/job';
import { Link } from 'react-router-dom';

function JobCard(props: Job) {
  return (
    <div className="rounded-md bg-white max-w-xs p-4">
      <div className="text-sm font-medium text-header">{props.title}</div>
      <div className="text-xs text-subtitle">{props.company}</div>
      <div className="my-2 text-xs text-subtitle">Candidates:</div>
      <div className="rounded-sm w-full p-2 bg-lightblue flex">
        <div className="flex-1 flex flex-col border-l-2 border-pblue pl-2">
          <div className="text-xs text-subtitle uppercase">Total</div>
          <div>0</div>
        </div>
        <div className="flex-1 flex flex-col border-l-2 border-pblue pl-2">
          <div className="text-xs text-subtitle uppercase">New</div>
          <div>0</div>
        </div>
      </div>
      <div className="flex space-x-4 my-2">
        <Badge color="green">Full Time</Badge>
        <Badge color="orange">Seniority</Badge>
      </div>
      <div className="flex justify-between border-t-1 border-[#F5F5F5] pt-2">
        <div className="flex text-xs text-subtitle">
          <DocumentCheckIcon className="h-4 w-4" />
          Published
        </div>
        <div className="text-xs text-subtitle">
          <Link to={`${props.id}`}>See Details &gt;</Link>
        </div>
      </div>
    </div>
  );
}

export default JobCard;
