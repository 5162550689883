import React from 'react';
import { Link } from 'react-router-dom';
import complete from '../assets/passwordResetComplete.svg';

function PasswordResetSuccesful() {
  return (
    <div className="flex w-screen items-center justify-center h-screen flex-row">
      <div className="h-screen flex w-9/12 justify-center items-center">
        <div className="flex w-8/12 flex-col">
          <div className="flex p-4 items-center justify-center">
            <img src={complete} alt="lock" />
          </div>
          <div className="flex p-4 items-center justify-center">
            <h1 className="text-3xl font-black">Password reset</h1>
          </div>
          <div className="flex p-4 items-center justify-center flex-col">
            <h1 className="text-sm text-subtitle flex text-center">Your password has been succesfully reset.</h1>
            <h1 className="text-sm text-subtitle flex text-center">Please click the link below to log in.</h1>
          </div>
          <div className="flex w-full p-4 items-center justify-center flex-col">
            <Link
              to="/login"
              className="btn btn-flex justify-center rounded-md border border-transparent bg-primary py-2 px-20 text-sm font-medium text-white shadow-sm"
            >
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetSuccesful;
