import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BillingInfo from './billing_info';
import BillingPayment from './billing_payment';
import clsx from 'clsx';
import { useAxios } from '../contexts/axios';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';

type PlanSku = 'basic' | 'business'; // | 'enterprise';
interface Price {
  monthly: number;
  annual: number;
}

export default function Billing() {
  const location = useLocation();
  const navigate = useNavigate();
  const { axios } = useAxios();
  const [currPlan, setCurrPlan] = useState<PlanSku>(location.state?.plan || 'business');
  const [infoComplete, setInfoComplete] = useState(false);
  const [isMonthly, setIsMonthly] = useState(location.state?.monthly);

  const prices: Record<PlanSku, Price> = {
    basic: {
      monthly: 0,
      annual: 0,
    },
    business: {
      monthly: 99,
      annual: 999,
    },
  };

  const planNames = {
    basic: 'Basic',
    business: 'Business',
    enterprise: 'Enterprise',
  };

  const selectedPrice = () => {
    if (isMonthly) {
      return prices[currPlan].monthly;
    } else {
      return prices[currPlan].annual;
    }
  };

  const selectedPeriod = () => {
    if (isMonthly) {
      return 'month';
    } else {
      return 'year';
    }
  };

  const selectedPriceWithPeriod = () => {
    return `${selectedPrice()}/${selectedPeriod()}`;
  };

  const planName = () => {
    return planNames[currPlan];
  };

  const toggleInfo = () => {
    setInfoComplete(!infoComplete);
  };

  const completePayment = () => {
    axios
      .put('/recruiters/billing', {
        organization: {
          plan_sku: currPlan,
          billing_period: isMonthly ? 'monthly' : 'annual',
        },
      })
      .then(() => {
        Mixpanel.track(MixpanelEventType.BILLING_PLAN_SUCCESS);
        navigate('/');
      })
      .catch((err) => {
        console.warn('error setting plan', err);
        Mixpanel.track(MixpanelEventType.BILLING_PLAN_FAILED);
      });
  };

  const trialEndDate = () => {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);
    return endDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  // unsure of what type to give (e: any) as any type given by VScode doesn't allow for me to select previousElementSibling
  const applyCouponCode = async (e: any) => {
    const prevSiblingElement = e.target.previousElementSibling;
    console.log(prevSiblingElement.value);
  };

  return (
    <section>
      <div className="flex flex-col md:flex-row gap-10 md:items-start">
        <div className="hidden lg:flex flex-col gap-5">
          <div className="flex flex-col bg-white p-12 rounded-xl min-w-[29vw]">
            <div className="border-b-2 border-subtitle/10 pb-5">
              <h2 className="mb-3 text-2xl font-medium">Order summary</h2>
              <div className="flex items-center gap-3">
                <span className="text-3xl font-medium">{planName()}</span>
                <span className="text-base text-primary font-bold cursor-pointer" onClick={() => navigate('../plans')}>
                  Change plan
                </span>
              </div>
            </div>
            <div className="border-b-2 border-subtitle/10 py-5 flex justify-between items-center gap-5">
              <h3 className="text-2xl w-40 font-semibold">Billing option</h3>
              <div className="flex">
                <label
                  className={clsx(
                    'px-4 py-2 rounded-l-xl border-r-0 cursor-pointer',
                    isMonthly ? 'bg-primary text-white' : 'border border-subtitle/20 text-subtitle'
                  )}
                >
                  Monthly
                  <input
                    className="hidden"
                    type="radio"
                    name="billingOption"
                    onClick={() => setIsMonthly(true)}
                    value="monthly"
                    defaultChecked={isMonthly}
                  />
                </label>
                <label
                  className={clsx(
                    'px-4 py-2 rounded-r-xl border-l-0 cursor-pointer',
                    !isMonthly ? 'bg-primary text-white' : 'border border-subtitle/20 text-subtitle'
                  )}
                >
                  Annually
                  <input
                    className="hidden"
                    type="radio"
                    name="billingOption"
                    onClick={() => setIsMonthly(false)}
                    value="annually"
                    defaultChecked={!isMonthly}
                  />
                </label>
              </div>
            </div>
            <div className="border-b-2 border-subtitle/10 py-5">
              <div className="flex justify-between">
                <div>Subtotal</div>
                <div>${selectedPriceWithPeriod()}</div>
              </div>
              <div className="flex justify-between">
                <div>Tax</div>
                <div>Not calculated</div>
              </div>
            </div>
            <div className="border-b-2 border-subtitle/10 py-5 flex justify-between">
              <div>Due in 14 days</div>
              <div>${selectedPrice()} plus tax</div>
            </div>
            <div className="pt-10 flex justify-between text-lg font-medium">
              <div>Due Today</div>
              <div>$0.00 USD</div>
            </div>
          </div>

          <label className="flex flex-col gap-3" htmlFor="couponCode">
            <span className="text-xl">Coupon code</span>
            <div className="flex gap-5">
              <input id="couponCode" type="text" placeholder="Coupon Code" />
              <button
                type="button"
                className="text-lg font-semibold text-primary cursor-pointer"
                onClick={applyCouponCode}
              >
                Apply
              </button>
            </div>
          </label>
        </div>

        <div className="flex flex-col gap-7  bg-white p-8 rounded-xl">
          {!infoComplete && <BillingInfo complete={toggleInfo} />}
          {infoComplete && <BillingPayment complete={completePayment} back={toggleInfo} />}
          <p className="text-subtitle text-sm lg:mr-20 text-center lg:text-left">
            By clicking above, you agree to our{' '}
            <span className="text-primary">Terms of Service, Cookies Policy, Privacy Policy</span>, and{' '}
            <span className="font-semibold">automatic renewal</span>. After the trial ends on <>{trialEndDate()}</>,
            your subscription will begin. You authorize this site, appearing as Prosper AI, to charge ${selectedPrice()}{' '}
            USD plus any tax each {selectedPeriod()} thereafter at the current rate until you cancel. Cancel any time in
            your account settings.
          </p>
        </div>
      </div>
    </section>
  );
}
