import React from 'react';
import { Job } from '../../models/job';
import JobCard from './JobCard';

interface IJobCardProps {
  jobs: Job[];
}

function JobCards(props: IJobCardProps) {
  return (
    <div className="flex flex-wrap gap-4">
      {props.jobs.map((job) => (
        <JobCard key={job.id} {...job} />
      ))}
    </div>
  );
}

export default JobCards;
