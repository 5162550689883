import React from 'react';
import { Link } from 'react-router-dom';
import lock from '../assets/passwordResetIcon.svg';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';

interface Props {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

function ResetPasswordEmail({ setEmail, email, setCompleted }: Props) {
  const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

  const handleSubmit = () => {
    if (regex.test(email)) {
      setCompleted(true);
      Mixpanel.track(MixpanelEventType.FORGOT_PASSWORD_SUBMIT);
    }
  };

  return (
    <div className="flex w-screen items-center justify-center h-screen flex-row">
      <div className="h-screen flex w-9/12 justify-center items-center">
        <div className="flex w-8/12 flex-col">
          <div className="flex p-4 items-center justify-center">
            <img src={lock} alt="lock" />
          </div>

          <div className="flex p-4 items-center justify-center">
            <h1 className="text-3xl font-black">Forgot password?</h1>
          </div>

          <div className="flex p-4 items-center justify-center">
            <h1 className="text-sm text-subtitle flex text-center">
              Enter the email address associated with your account, and we will send you a reset link!
            </h1>
          </div>

          <form
            onSubmit={() => {
              setEmail(email);
              handleSubmit();
            }}
          >
            <div className="flex p-4 items-left justify-center flex-col">
              <label htmlFor="email">Company Email Address</label>
              <input
                id="email"
                type="email"
                required
                placeholder="Company email address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>
            <div className="flex w-full p-4 items-center justify-center flex-col">
              <div>
                <button type="submit" onClick={handleSubmit} className="btn btn-primary">
                  Reset password
                </button>
              </div>
              <Link to="/login" className="p-2 text-sm text-subtitle flex flex-row items-center">
                <ArrowSmallLeftIcon className="h-4" /> Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordEmail;
