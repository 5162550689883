import React, { useState } from 'react';
import PasswordResetEmail from './passwordResetEmail';
import PasswordResetVerifyEmail from './passwordResetVerifyEmail';
import FeaturesPanel from './featuresPanel';

function PasswordReset() {
  const [completed, setCompleted] = useState(false);
  const [email, setEmail] = useState('');

  return (
    <div>
      <div className="flex h-screen flex-row w-full">
        <FeaturesPanel className="hidden lg:flex flex-1 w-5/12" />
        <div className="lg:w-7/12 flex bg-lightblue">
          {completed ? (
            <PasswordResetVerifyEmail email={email} />
          ) : (
            <PasswordResetEmail setEmail={setEmail} email={email} setCompleted={setCompleted} />
          )}
        </div>
      </div>
    </div>
  );
}
export default PasswordReset;
