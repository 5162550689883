import React from 'react';
import { useAuth } from '../contexts/user';

function Dashboard() {
  const auth = useAuth();

  return (
    <>
      <h1 className="text-gray-700 text-xs uppercase tracking-wider">Dashboard</h1>
      <div>User ID: {auth.user?.id}</div>
      <div>Email: {auth.user?.email}</div>
      <div>First Name: {auth.user?.first_name}</div>
      <div>Last Name: {auth.user?.last_name}</div>
      <div>Organization: {auth.user?.organization?.name}</div>
      <div>Org Status: {auth.user?.organization?.status}</div>
    </>
  );
}

export default Dashboard;
