import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardCvcElementOptions,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { useAxios } from '../contexts/axios';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';

const stripeStyle: StripeElementStyle = {
  base: {
    color: 'rgb(153 159 169)',
    fontSize: '16px',
    fontFamily: 'Poppins',
  },
};

const cardOptions: StripeCardNumberElementOptions = {
  style: stripeStyle,
  showIcon: true,
};

const expiryOptions: StripeCardExpiryElementOptions = {
  style: stripeStyle,
};

const cvcOptions: StripeCardCvcElementOptions = {
  style: stripeStyle,
};

interface IProps {
  complete: VoidFunction;
  back: VoidFunction;
}

export default function BillingPayment(props: IProps) {
  const stripe = useStripe();
  const elements = useElements();
  const { axios } = useAxios();
  const [paymentType, setPaymentType] = useState<'card' | 'bank' | 'paypal'>('card');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardNumElem = elements.getElement(CardNumberElement);

    if (cardNumElem) {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumElem,
        // billing_details: {
        //   address: {
        //     postal_code: billingInfo.billing_zip,
        //   },
        // },
      });

      if (result.error) {
        console.log('Error creating PaymentMethod', result.error);
        Mixpanel.track(MixpanelEventType.BILLING_STRIPE_PAYMENT_FAILED, {
          error_code: result.error.code,
        });
        setLoading(false);
      } else {
        console.log('Created PaymentMethod', result.paymentMethod);
        Mixpanel.track(MixpanelEventType.BILLING_STRIPE_PAYMENT_SUCCESS);
        axios
          .post('/recruiters/billing/payment_methods', {
            payment_method_id: result.paymentMethod.id,
          })
          .then(() => {
            props.complete();
            Mixpanel.track(MixpanelEventType.BILLING_PAYMENT_SUCCESS);
          })
          .catch(() => {
            setLoading(false);
            Mixpanel.track(MixpanelEventType.BILLING_PAYMENT_FAILED);
          });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="font-bold text-lg">Payment Method</h1>

      <h3 className="flex text-sm font-semibold mt-5">Pay With</h3>
      <div className="flex flex-col sm:flex-row gap-5 border-b border-subtitle/10 mt-5 pb-4">
        <label className="inline-flex items-center gap-2 cursor-pointer" htmlFor="card">
          <input
            className="peer hidden"
            id="card"
            type="radio"
            name="paymentMethod"
            onClick={() => setPaymentType('card')}
            defaultChecked={paymentType === 'card' ? true : false}
            value="card"
          />
          {/* Custom Radio Input */}
          <div className="h-[1.5em] w-[1.5em] p-1 border-2 border-subtitle rounded-full after:w-full after:h-full after:block after:bg-primary after:rounded-full after:scale-0 peer-checked:after:scale-100 peer-checked:border-primary"></div>
          Credit/ Debit Card
        </label>

        <label className="inline-flex items-center gap-2 cursor-pointer" htmlFor="bank">
          <input
            className="peer hidden"
            id="bank"
            type="radio"
            name="paymentMethod"
            onClick={() => setPaymentType('bank')}
            defaultChecked={paymentType === 'bank' ? true : false}
            value="bank"
          />
          {/* Custom Radio Input */}
          <div className="h-[1.5em] w-[1.5em] p-1 border-2 border-subtitle rounded-full after:w-full after:h-full after:block after:bg-primary after:rounded-full after:scale-0 peer-checked:after:scale-100 peer-checked:border-primary"></div>
          Bank Transfer
        </label>

        <label className="inline-flex items-center gap-2 cursor-pointer" htmlFor="paypal">
          <input
            className="peer hidden"
            id="paypal"
            type="radio"
            name="paymentMethod"
            onClick={() => setPaymentType('paypal')}
            defaultChecked={paymentType === 'paypal' ? true : false}
            value="paypal"
          />
          {/* Custom Radio Input */}
          <div className="h-[1.5em] w-[1.5em] p-1 border-2 border-subtitle rounded-full after:w-full after:h-full after:block after:bg-primary after:rounded-full after:scale-0 peer-checked:after:scale-100 peer-checked:border-primary"></div>
          Paypal
        </label>
      </div>

      <div className="pr-[5%] lg:pr-[20%] mt-5">
        <label className="font-medium block mb-2" htmlFor="apt">
          Card Details
        </label>
        <CardNumberElement options={cardOptions} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full pr-[5%] lg:pr-[20%] mt-5">
        <label className="font-medium flex flex-col gap-2" htmlFor="expirationDate">
          Expiration Date
          <CardExpiryElement options={expiryOptions} />
        </label>

        <label className="font-medium flex flex-col gap-2" htmlFor="cvv">
          CVV
          <CardCvcElement options={cvcOptions} />
        </label>
      </div>

      {/** Back & 'Start my free trial' Button */}
      <div className="flex flex-row items-center mt-5">
        <button
          className="inline-flex text-primary font-medium items-center hover:underline no-underline md:mr-4 mr-3"
          onClick={() => props.back()}
        >
          <ChevronLeftIcon className="w-5 h-5 text-primary" strokeWidth={2.5} />
          Back
        </button>

        <button
          type="submit"
          className="btn btn-primary md:px-10 md:py-4 py-2 px-[9px] max-w-sm md:w-58"
          disabled={loading}
        >
          Start my free trial
        </button>
      </div>
    </form>
  );
}
