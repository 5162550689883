import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useAxios } from '../contexts/axios';
import { useAuth } from '../contexts/user';
import FeaturesPanel from './featuresPanel';
import CheckMarkCircle from './shared/icons/CheckMarkCircle';
import LoadingSpinner from './shared/loadingSpinner';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';

function ConfirmEmailVerified() {
  const [validUrl, setValidUrl] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  /* const [isEmailAlreadyConfirmed, setIsEmailAlreadyConfirmed] = useState<boolean>(false); */
  const { token } = useParams();
  const { axios } = useAxios();
  const auth = useAuth();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      await axios
        .get(`/recruiters/confirmation?confirmation_token=${token}`)
        .then((resp) => {
          Mixpanel.track(MixpanelEventType.CONFIRM_EMAIL_SUCCESS);
          auth.signin(resp.data);
          setValidUrl(true);
        })
        .catch((error) => {
          Mixpanel.track(MixpanelEventType.CONFIRM_EMAIL_INVALID);
          setValidUrl(false);
          console.log({ error: `${Object.keys(error.response.data)[0]} ${Object.values(error.response.data)[0]}` });
          // TODO possibly inform user email is already confirmed despite error.response.status === 422?
          /* if (error.response.data?.email) {
            setIsEmailAlreadyConfirmed(true);
          } */
        })
        .finally(() => {
          setLoading(false);
        });
    };

    // delay api call
    const delayFetchTimer = setTimeout(() => {
      verifyEmailUrl();
    }, 1000);

    // clean up (useEffect) function - clear timer
    return () => clearTimeout(delayFetchTimer);
  }, []);

  //TODO should the app loading indicator be here instead of LoadingSpinner component?
  if (loading) {
    return (
      <div className="w-full justify-center flex h-screen mx-auto items-center">
        <LoadingSpinner className="w-10 text-primary fill-white" />
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      {validUrl ? (
        <>
          <FeaturesPanel className="hidden lg:flex flex-1 w-0" />

          {/** Showcase and form split  */}
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-56">
            <div className="w-full mx-auto max-w-sm lg:w-96">
              <div>
                {/** 'CheckMarkCircle' icon */}
                <CheckMarkCircle
                  className="w-[256px]
              -mt-20
              mx-auto"
                />
              </div>
              <div>
                <h1 className="text-2xl font-black text-center">Verified!</h1>
              </div>
              <div className="mt-5">
                <p className="text-sm text-[#858585] text-center font-[600]">
                  Yay! you have successfully verified your account.
                </p>
              </div>
              <div>
                <Link to={'/onboard/user'}>
                  <button className="mx-auto mt-10 flex rounded-md border border-transparent bg-primary md:py-4 md:px-28 py-3 px-12 text-sm font-medium text-white shadow-sm">
                    Setup profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : (
        /**
         * TODO if user exits tab or email already confirmed show error content and exit onboarding track/path...
         *  navigates to home page or allow user to continue onboarding? - possibly a different task/ticket?
         */
        <div className="flex h-screen mx-auto items-center">
          <div>
            <div>
              {/** 'CheckMarkCircle' icon */}
              <ExclamationCircleIcon
                className="w-[256px]
              -mt-20
              mx-auto
              text-black
              "
              />
            </div>
            <div>
              <h1 className="text-2xl font-black text-center">Verification Invalid!</h1>
            </div>
            <div className="mt-5">
              <p className="text-sm text-red-500 text-center font-[600]">Email verification link expired or invalid.</p>
            </div>
            <div>
              <Link to={'/'}>
                <button className="mx-auto mt-10 flex rounded-md border border-transparent bg-primary md:py-4 md:px-28 py-3 px-12 text-sm font-medium text-white shadow-sm">
                  Go to home page
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfirmEmailVerified;
