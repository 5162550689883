import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import NewJobGeneral from './NewJobGeneral';

const CreateJob = () => {
  const [dropDown, setDropDown] = useState(false);

  return (
    <div className="flex-grow flex flex-col">
      <div className="font-semibold text-xl mb-5">
        <Link className="hover:border-b-2 hover:border-black" to="/jobs">
          Jobs
        </Link>{' '}
        <ChevronRightIcon className="h-8 fill-primary inline" />
        Create a new job post
      </div>

      <div className="flex-grow flex flex-col xl:flex-row gap-10 px-5">
        {/* Current State of CreateJob */}

        <NewJobGeneral />
        {/* <NewJobDetails /> */}

        <div className="-order-1 xl:order-1 bg-white rounded-lg p-8 border border-subtitle/20 self-center xl:self-start">
          <div
            className="flex gap-20 text-xl font-semibold whitespace-nowrap"
            onClick={() => setDropDown((prev) => !prev)}
          >
            How does it Work?
            <ChevronDownIcon
              className={clsx('h-8 inline cursor-pointer transition-all', dropDown && '-rotate-90')}
              role="button"
              aria-hidden="false"
            />
          </div>

          {/* How does it Work Content */}
          {dropDown && (
            <div className="mt-5">
              <h1 className="">Placeholder</h1>
              <p className="">How does it Work?</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateJob;
