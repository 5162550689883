import { Menu, Transition } from '@headlessui/react';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Job } from '../../models/job';

interface IJobsTableProps {
  jobs: Job[];
}

function JobsTable(props: IJobsTableProps) {
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState<Job[]>([]);

  useLayoutEffect(() => {
    const isIndeterminate = selectedJobs.length > 0 && selectedJobs.length < props.jobs.length;
    setChecked(selectedJobs.length === props.jobs.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedJobs]);

  function toggleAll() {
    setSelectedJobs(checked || indeterminate ? [] : props.jobs);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <div>
      <table className="min-w-full table-fixed divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
              <input
                type="checkbox"
                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary sm:left-6"
                ref={checkbox}
                checked={checked}
                onChange={toggleAll}
              />
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              ID #
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Job Title
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Company
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Salary
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Menu</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {props.jobs.map((job) => (
            <tr key={job.id} className={clsx(selectedJobs.includes(job) && 'bg-gray-50')}>
              <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                {selectedJobs.includes(job) && <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />}
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:primary sm:left-6"
                  value={job.id}
                  checked={selectedJobs.includes(job)}
                  onChange={(e) =>
                    setSelectedJobs(e.target.checked ? [...selectedJobs, job] : selectedJobs.filter((p) => p !== job))
                  }
                />
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.id}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.title}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.company}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.salary}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <select
                  className={clsx(
                    'block w-full flex-1 rounded-md border-gray-300 px-2 py-2 focus:border-primary focus:ring-primary text-sm',
                    job.status === 'active' ? 'text-primary' : 'text-gray-300'
                  )}
                  value={job.status}
                >
                  <option value="active">Active</option>
                  <option value="disabled">Disabled</option>
                </select>
              </td>
              <td className="px-2">
                <Menu as="div">
                  <div>
                    <Menu.Button className="">
                      <span className="sr-only">Open job menu</span>
                      <EllipsisHorizontalIcon className="h-5 w-5" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <Link to={`${job.id}`} className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700">
                          <EyeIcon className="h-4 w-4" />
                          View Details
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to={`${job.id}`} className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700">
                          <PencilIcon className="h-4 w-4" />
                          Edit
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="" className="flex items-center gap-2 px-4 py-2 text-sm text-red-700">
                          <TrashIcon className="h-4 w-4" />
                          Delete
                        </Link>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default JobsTable;
