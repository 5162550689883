import React from 'react';
import FeaturesPanel from './featuresPanel';
import MarkEmailRead from './shared/icons/MarkEmailRead';

function VerifyEmail() {
  return (
    <div className="flex h-screen">
      <FeaturesPanel className="hidden lg:flex flex-1 w-0" />

      {/** Showcase and form split  */}
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-56">
        <div className="w-full mx-auto max-w-sm lg:w-96">
          <div>
            {/** 'MarkEmailRead' icon */}
            <MarkEmailRead
              className="w-[256px]
              -mt-20
              mx-auto"
            />
          </div>
          <div>
            <h1 className="text-2xl font-black text-center">Verify your email address</h1>
          </div>
          <div className="mt-5 text-center">
            <p className="text-sm text-[#858585] font-[500]">
              You’ve almost signed up! An email verification link was sent to your entered email address.
            </p>
            <p className="text-sm text-[#858585] font-[500] mt-3">
              Please verify your email address by clicking on the link in your inbox. If you don&apos;t see it you may
              need to check your <span className="font-bold  text-black">junk or spam</span>&nbsp;folder.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
