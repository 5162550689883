import clsx from 'clsx';
import React, { ReactNode } from 'react';

interface IBadgeProps {
  color: 'green' | 'orange';
  children: ReactNode;
}

const colorClasses = {
  green: 'text-pgreen bg-pgreen/10',
  orange: 'text-porange bg-porange/10',
};

function Badge(props: IBadgeProps) {
  return (
    <span className={clsx('inline-flex items-center rounded-md py-0.5 px-4 text-xs', colorClasses[props.color])}>
      {props.children}
    </span>
  );
}

export default Badge;
