import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import Logo from './shared/images/Logo';
import { useAuth } from '../contexts/user';

const stripePromise = loadStripe(
  'pk_test_51LqSRYJLv1pJKVphvt30OOn5iGB3hZq0rC9DSf6TE4rFNm3BN6pQqYxJfkuQDkcQrWysbjb1xoVmcWF7pUgx9i7s00HjSOIeee'
); // TODO: use envar for this once we are ready for prod use.

const stripeOptions: StripeElementsOptions = {
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins' }],
};

function Onboarding() {
  const auth = useAuth();
  const location = useLocation();

  if (auth.user?.organization.status !== 'onboarding') {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <div className="min-h-screen bg-lightblue p-10">
        <div className="p-4 w-3/12">
          <Logo className="text-primary h-12 mb-12" />
        </div>
        <Outlet />
      </div>
    </Elements>
  );
}

export default Onboarding;
