import React from 'react';
import clsx from 'clsx';
import Logo from './shared/images/Logo';

interface IProps {
  className?: string;
}

function FeaturesPanel(props: IProps) {
  return (
    <div className={clsx('flex-col justify-center bg-primary', props.className)}>
      <div className="flex flex-col max-w-sm mx-auto">
        <div>
          <Logo className="text-white h-10" />
        </div>
        <h1 className="text-5xl font-semibold text-white my-6">One tool for your whole team needs</h1>
        <p className="text-white">We provide you the premier tool to innovate your team&apos;s talent stack.</p>

        {/** >>Features wrapper<< */}
        <div className="flex flex-col  my-5 border-yellow-100">
          {/** Fast & Easy Feature container Wrapper */}
          <div className="flex flex-col  mb-4 ">
            {/** Fast & Easy Main Row */}
            <div className="flex flex-row   border-black-500">
              <svg className="mr-2 w-6 h-6" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.2793 16.5123V7.51233H13.2793V0.512329H20.2793L18.2793 5.51233H20.2793L15.2793 16.5123ZM13.2793 9.51233V16.5123H2.2793C1.1793 16.5123 0.279297 15.6123 0.279297 14.5123V11.5123C0.279297 10.4123 1.1793 9.51233 2.2793 9.51233H13.2793ZM4.5293 12.2623H3.0293V13.7623H4.5293V12.2623ZM11.2793 0.512329V7.51233H2.2793C1.1793 7.51233 0.279297 6.61233 0.279297 5.51233V2.51233C0.279297 1.41233 1.1793 0.512329 2.2793 0.512329H11.2793ZM4.5293 3.26233H3.0293V4.76233H4.5293V3.26233Z"
                  fill="#F3FCFF"
                />
              </svg>

              <h1 className="font-bold text-white">Fast & Easy</h1>
            </div>
            {/** Fast & Easy Sub Row */}
            <div className="flex flex-row ml-8 ">
              <h1 className="text-[#A9D0DB] font-bold text-sm">Get access instantly, no downloads required</h1>
            </div>
          </div>

          {/** Own Data Feature container Wrapper */}
          <div className="flex flex-col mb-4">
            {/** Own Data Main Row */}
            <div className="flex flex-row">
              <svg className="mr-2 w-6 h-6" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.2793 2.51233H12.0993C11.6793 1.35233 10.5793 0.512329 9.2793 0.512329C7.9793 0.512329 6.8793 1.35233 6.4593 2.51233H2.2793C1.1793 2.51233 0.279297 3.41233 0.279297 4.51233V18.5123C0.279297 19.6123 1.1793 20.5123 2.2793 20.5123H16.2793C17.3793 20.5123 18.2793 19.6123 18.2793 18.5123V4.51233C18.2793 3.41233 17.3793 2.51233 16.2793 2.51233ZM9.2793 2.51233C9.8293 2.51233 10.2793 2.96233 10.2793 3.51233C10.2793 4.06233 9.8293 4.51233 9.2793 4.51233C8.7293 4.51233 8.2793 4.06233 8.2793 3.51233C8.2793 2.96233 8.7293 2.51233 9.2793 2.51233ZM9.2793 6.51233C10.9393 6.51233 12.2793 7.85233 12.2793 9.51233C12.2793 11.1723 10.9393 12.5123 9.2793 12.5123C7.6193 12.5123 6.2793 11.1723 6.2793 9.51233C6.2793 7.85233 7.6193 6.51233 9.2793 6.51233ZM15.2793 18.5123H3.2793V17.1123C3.2793 15.1123 7.2793 14.0123 9.2793 14.0123C11.2793 14.0123 15.2793 15.1123 15.2793 17.1123V18.5123Z"
                  fill="#F3FCFF"
                />
              </svg>

              <h1 className="font-bold text-white">Big teams or solopreneurs we can serve you</h1>
            </div>
            {/** Own Data Sub Row */}
            <div className="flex flex-row ml-8">
              <h1 className="text-[#A9D0DB] font-bold text-sm">Enjoy your first job on us</h1>
            </div>
          </div>

          {/** Unlimited Features Feature container Wrapper */}
          <div className="flex flex-col">
            {/** Unlimited Features Main Row */}
            <div className="flex flex-row ">
              <svg className="mr-2 w-6 h-6" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.2693 0.512329C4.7493 0.512329 0.279297 4.99233 0.279297 10.5123C0.279297 16.0323 4.7493 20.5123 10.2693 20.5123C15.7993 20.5123 20.2793 16.0323 20.2793 10.5123C20.2793 4.99233 15.7993 0.512329 10.2693 0.512329ZM14.5093 16.5123L10.2793 13.9623L6.0493 16.5123L7.1693 11.7023L3.4393 8.47233L8.3593 8.05233L10.2793 3.51233L12.1993 8.04233L17.1193 8.46233L13.3893 11.6923L14.5093 16.5123Z"
                  fill="#F3FCFF"
                />
              </svg>

              <h1 className="font-bold text-white">Unlimited Features</h1>
            </div>
            {/** Unlimited Features Features Sub Row */}
            <div className="flex flex-row ml-8  ">
              <h1 className="text-[#A9D0DB] font-bold text-sm">
                Access all features of the world&apos;s #1 talent acquisition software from minute 1
              </h1>
            </div>
          </div>
        </div>
        {/** >>Features wrapper End<< */}
      </div>
    </div>
  );
}

export default FeaturesPanel;
