import React from 'react';
import { Link } from 'react-router-dom';
import check from '../assets/passwordResetEmailIcon.svg';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';

type Props = {
  email: string;
};

function PasswordResetVerifyEmail({ email }: Props) {
  return (
    <div className="flex w-screen items-center justify-center h-screen flex-row">
      <div className="h-screen flex w-9/12 justify-center items-center">
        <div className="flex w-8/12 flex-col">
          <div className="flex p-4 items-center justify-center">
            <img src={check} alt="lock" />
          </div>

          <div className="flex p-4 items-center justify-center">
            <h1 className="text-3xl font-black">Check your email</h1>
          </div>

          <div className="flex p-4 items-center justify-center flex-row">
            <h1 className="text-sm text-subtitle flex text-center">We have sent an email to&nbsp;</h1>
            <h1 className="text-black text-sm">{email}</h1>
          </div>

          <form>
            <div className="flex w-full p-4 items-center justify-center flex-col">
              <Link to="/login" className="p-2 text-sm text-subtitle flex flex-row items-center">
                <ArrowSmallLeftIcon className="h-4" /> Back to Login
              </Link>
            </div>
            <div className="flex w-full p-4 items-center justify-center flex-col text-sm">
              <h1 className="text-subtitle">
                Didn&apos;t receive the email?&nbsp;
                <a className="text-primary">Click to resend</a>
              </h1>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetVerifyEmail;
