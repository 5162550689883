import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../contexts/axios';
import { useAuth } from '../contexts/user';
import { Mixpanel, MixpanelEventType } from '../services/mixpanel';

interface IBillingInfo {
  billing_first_name?: string;
  billing_last_name?: string;
  billing_email?: string;
  billing_street1?: string;
  billing_street2?: string;
  billing_state?: string;
  billing_zip?: string;
}

interface IProps {
  complete: VoidFunction;
}

export default function BillingInfo(props: IProps) {
  const { axios } = useAxios();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [billingInfo, setBillingInfo] = useState<IBillingInfo>({
    billing_first_name: user?.organization.billing_first_name,
    billing_last_name: user?.organization.billing_last_name,
    billing_email: user?.organization.billing_email,
    billing_street1: user?.organization.billing_street1,
    billing_street2: user?.organization.billing_street2,
    billing_state: user?.organization.billing_state,
    billing_zip: user?.organization.billing_zip,
  });
  const [loading, setLoading] = useState(false);

  const saveBillingInfo = () => {
    axios
      .put('/recruiters/billing', {
        organization: billingInfo,
      })
      .then(() => {
        props.complete();
        Mixpanel.track(MixpanelEventType.BILLING_INFO_SUCCESS);
      })
      .finally(() => {
        setLoading(false);
        Mixpanel.track(MixpanelEventType.BILLING_INFO_FAILED);
      });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    saveBillingInfo();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setBillingInfo({ ...billingInfo, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="font-bold text-lg">Billing Information</h1>
      <div className="grid grid-cols-2 gap-3 w-full pr-[5%] lg:pr-[20%] mt-5">
        <label className="flex flex-col gap-1" htmlFor="billing_first_name">
          First Name
          <input
            type="text"
            required
            name="billing_first_name"
            pattern={'[a-zA-Z]+'}
            placeholder="First Name"
            onChange={handleChange}
            value={billingInfo.billing_first_name}
          />
        </label>

        <label className="flex flex-col gap-1" htmlFor="billing_last_name">
          Last Name
          <input
            type="text"
            required
            name="billing_last_name"
            pattern={'[a-zA-Z]+'}
            placeholder="Last Name"
            onChange={handleChange}
            value={billingInfo.billing_last_name}
          />
        </label>
      </div>

      <div className="pr-[5%] lg:pr-[20%] mt-5">
        <label htmlFor="billing_email">Email Address</label>
        <input
          type="email"
          required
          id="billing_email"
          name="billing_email"
          placeholder="Email Address"
          onChange={handleChange}
          value={billingInfo.billing_email}
        />
      </div>
      <div className="pr-[5%] lg:pr-[20%] mt-5">
        <label htmlFor="billing_street1">Street Address</label>
        <input
          type="text"
          required
          id="billing_street1"
          name="billing_street1"
          placeholder="Street Address"
          onChange={handleChange}
          value={billingInfo.billing_street1}
        />
      </div>
      <div className="pr-[5%] lg:pr-[20%] mt-5">
        <label htmlFor="billing_street2">Apt, suite, etc. (optional)</label>
        <input
          type="text"
          id="billing_street2"
          name="billing_street2"
          placeholder="Apt, suite, etc. (optional)"
          onChange={handleChange}
          value={billingInfo.billing_street2}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full pr-[5%] lg:pr-[20%] mt-5">
        <div>
          <label htmlFor="country">Country</label>
          <select name="country" id="country" defaultValue="" required>
            <option value="" disabled>
              Country
            </option>
            <option value="US">United States</option>
          </select>
        </div>
        <div>
          <label htmlFor="billing_state">State</label>
          <select
            name="billing_state"
            id="billing_state"
            defaultValue=""
            required
            onChange={handleChange}
            value={billingInfo.billing_state}
          >
            <option value="" disabled>
              State
            </option>
            <option value="ohio">OH</option>
          </select>
        </div>
        <div>
          <label htmlFor="billing_zip">Zipcode</label>
          <input
            type="text"
            required
            id="billing_zip"
            name="billing_zip"
            onChange={handleChange}
            value={billingInfo.billing_zip}
            pattern={'[0-9]{5}'}
            maxLength={5}
            placeholder="Zipcode"
          />
        </div>

        {/** Back and 'Save & Continue' button */}
        <div className="flex flex-row w-full max-w-sm md:w-96 items-center mt-5">
          <button
            className="inline-flex text-primary font-medium items-center hover:underline no-underline mr-5"
            onClick={() => navigate('/onboard/plans')}
          >
            <ChevronLeftIcon className="w-5 h-5 text-primary" strokeWidth={2.5} />
            Back
          </button>

          <button type="submit" className="btn btn-primary md:px-10 md:py-4 py-2 px-[9px]" disabled={loading}>
            Save & Continue
          </button>
        </div>
      </div>
    </form>
  );
}
