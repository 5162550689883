import clsx from 'clsx';
import React from 'react';

export default function Loader({ className }: { className: string }) {
  return (
    // Use before: to change the loader itself
    <div
      className={clsx('before:animate-loading before:bg-primary before:rounded-full before:h-8 before:w-8', className)}
    ></div>
  );
}
