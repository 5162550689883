import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ListBulletIcon, Squares2X2Icon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import JobCards from './JobCards';
import JobsTable from './JobsTable';
import { Job } from '../../models/job';
import { useAxios } from '../../contexts/axios';
import Loader from '.././Loader';
import { useAuth } from '../../contexts/user';
import { useNavigate } from 'react-router-dom';

function Jobs() {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableView, setTableView] = useState(false);
  const { axios } = useAxios();

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('/job_postings').then(
      (res) => {
        setJobs(res.data);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );
  }, []);

  return loading ? (
    <Loader className="absolute flex top-1/2 left-1/2" />
  ) : (
    <div className="flex-grow flex flex-col">
      <h1 className="text-2xl text-header font-semibold">Jobs Postings</h1>
      <div className="flex items-center gap-2 my-2">
        <div className="flex-grow">
          {' '}
          {/* results */}
          <div className="font-medium">Showing {jobs.length} Results</div>
          <div className="text-xs text-subtitle">Based on your preferences</div>
        </div>
        <div>
          {' '}
          {/* search */}
          <form className="w-full md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative focus-within:text-gray-600">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block h-8 w-full rounded-md border-gray-300 py-2 pl-8 pr-3 text-sm leading-5 focus:outline-none focus:ring-0"
                placeholder="Search Jobs..."
                type="search"
                name="search"
              />
            </div>
          </form>
        </div>
        <div>
          {' '}
          {/* view buttons */}
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={clsx(
                'relative inline-flex items-center rounded-l-md border border-gray-300 px-2 py-2 text-sm font-medium focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary',
                tableView ? 'bg-white text-gray-500 hover:bg-gray-50' : 'bg-primary text-white'
              )}
              onClick={() => setTableView(false)}
            >
              <span className="sr-only">Card View</span>
              <Squares2X2Icon className="h-4 w-4" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={clsx(
                'relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-2 py-2 text-sm font-medium focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary',
                tableView ? 'bg-primary text-white' : 'bg-white text-gray-500 hover:bg-gray-50'
              )}
              onClick={() => setTableView(true)}
            >
              <span className="sr-only">Table View</span>
              <ListBulletIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </span>
        </div>
        <div className="flex rounded-md shadow-sm">
          {' '}
          {/* sort */}
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-white px-3 text-gray-500 text-sm">
            Sort by:
          </span>
          <select className="block w-full flex-1 rounded-r-md border-l-0 border-gray-300 px-8 py-2 focus:border-primary focus:ring-primary text-sm">
            <option>Newest</option>
          </select>
        </div>
      </div>
      {jobs.length === 0 ? (
        <div className="bg-white flex flex-col gap-5 justify-center items-center rounded-lg flex-grow mt-3">
          <h1 className="text-4xl font-medium">You don’t have any job postings.</h1>
          <p className="text-subtitle">Create a new job post</p>
          <button
            className="bg-primary text-white font-semibold py-8 px-14 rounded-3xl"
            onClick={() => navigate('create')}
          >
            Post a new job
          </button>
        </div>
      ) : tableView ? (
        <JobsTable jobs={jobs} />
      ) : (
        <JobCards jobs={jobs} />
      )}
    </div>
  );
}

export default Jobs;
