import React, { useState } from 'react';
import { Job } from '../../models/job';

interface IJobDetailsProps {
  job?: Job;
}

export default function JobDetails({ job }: IJobDetailsProps) {
  const [isEdit, setIsEdit] = useState(false);

  return (
    // All direct children--> [&>*]:
    <div className="px-8 pb-10 [&>*]:border-b-[#E8E8E8] [&>*]:border-b-2 [&>*]:pb-10">
      {/* Logo + Edit/Delete Buttons */}
      <div className="flex justify-between">
        <div className="">
          <img className="rounded-xl" src="https://via.placeholder.com/86" alt="Company Logo" />
          <div className="text-xs mt-5 text-subtitle">{job?.company}</div>
          <div className="text-2xl font-semibold">{job?.title}</div>
        </div>

        <div className="self-center flex gap-5">
          <button
            className="border-[#E8E8E8] border-2 rounded-xl py-2 px-14"
            onClick={() => setIsEdit((prev) => !prev)}
          >
            Edit
          </button>
          <button className="bg-[#D2272A] text-white rounded-xl py-2 px-14">Delete</button>
        </div>
      </div>

      {/* Job Description */}
      <div className="mt-10 flex flex-col gap-5">
        <div>
          <span className="font-semibold">Company’s industry</span>
          <p className="">Technology</p>
        </div>

        <div>
          <span className="font-semibold">Job title</span>
          <p className="">{job?.title}</p>
        </div>

        <div>
          <span className="font-semibold">Job location</span>
          <p className="">Software Engineer</p>
        </div>

        <div>
          <span className="font-semibold">Job descriptiton</span>
          <p>
            The primary function of this role is to be responsible for understanding the business and how to
            apply/augment the IT application architecture to plan, design, build, and integrate state of the art web
            solutions for both internal and public-facing applications. This role requires a strong understanding and
            experience with mobile enabled / responsive design web applications, messaging buses, micro services, and
            relational database architecture. We are seeking a Lead Software Engineer to...
          </p>
          <div className="text-sm text-primary font-bold mt-3 cursor-pointer">View more</div>
        </div>
      </div>

      {/* Job Details */}
      <div className="mt-10 flex flex-col gap-5">
        <div className="text-xl font-bold">Job Details</div>
        <div>
          <span className="font-semibold">Empolyment type</span>
          <p>Full time</p>
        </div>

        <div>
          <span className="font-semibold">Number of openings</span>
          <p>2</p>
        </div>

        <div>
          <span className="font-semibold">Experience level</span>
          <p>Senior</p>
        </div>

        <div>
          <span className="font-semibold">Min experience</span>
          <p>2 years and above</p>
        </div>

        <div>
          <span className="font-semibold">Time zone</span>
          <p>PST</p>
        </div>

        <div>
          <div className="font-semibold">Start date</div>
          <div>12 July 2022</div>
        </div>
      </div>

      {/* Compensation details */}
      <div className="mt-10 flex flex-col gap-5">
        <div className="text-xl font-bold">Compensation details</div>
        <div>
          <span className="font-semibold">Amount</span>
          <p>$ {job?.salary}</p>
        </div>

        <div>
          <span className="font-semibold">Rate</span>
          <p>Per Hour</p>
        </div>

        <div>
          <span className="font-semibold">Supplemental pay</span>
          <p>Signing bonus</p>
        </div>

        <div className="flex flex-col">
          <span className="font-semibold">Benefits</span>
          <ul>
            <li>Health insurance </li>
            <li> Flexible schedule</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
