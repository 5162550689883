import React, { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3BottomLeftIcon,
  BellIcon,
  BriefcaseIcon,
  CalendarIcon,
  ChartBarSquareIcon,
  Cog6ToothIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  StarIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { Link, NavLink, Outlet } from 'react-router-dom';
import logo from '../assets/logo.svg';
import clsx from 'clsx';
import { useAuth } from '../contexts/user';

const navigation = [
  {
    section: '',
    children: [
      { name: 'Dashboard', href: '/', icon: HomeIcon },
      { name: 'Calendar', href: '/calendar', icon: CalendarIcon },
      { name: 'Statistics', href: '/stats', icon: ChartBarSquareIcon },
    ],
  },
  {
    section: 'RECRUITMENT',
    children: [
      { name: 'Job Postings', href: '/jobs', icon: BriefcaseIcon },
      { name: 'Candidates', href: '/candidates', icon: StarIcon },
    ],
  },
  {
    section: 'ORGANIZATION',
    children: [
      { name: 'Recruiters', href: '/users', icon: UsersIcon },
      { name: 'Settings', href: '/settings', icon: Cog6ToothIcon },
    ],
  },
];

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
];

function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const auth = useAuth();

  const logout = () => {
    auth.signout();
  };

  return (
    <>
      {/* top nav */}
      <nav className="fixed top-0 h-20 inset-x-0 z-50 text-white bg-primary font-medium flex justify-between items-center shadow-lg">
        <button
          type="button"
          className="border-r border-white px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white md:hidden"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-shrink-0 items-center px-4 bg-primary">
          <img className="h-10 w-auto" src={logo} alt="logo" />
        </div>
        <div className="flex flex-1 justify-between px-4">
          <div className="flex flex-1 items-center">
            <form className="w-full md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative text-lightblue focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block h-10 w-full rounded-full border-transparent py-2 pl-8 pr-3 leading-5 text-lightblue placeholder-lightblue bg-white/10 focus:bg-white focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                />
              </div>
            </form>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <button
              type="button"
              className="rounded-lg bg-white/10 p-2 text-white hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-2"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Profile Dropdown*/}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-10 w-10 rounded-full border border-white"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          to={item.href}
                          className={clsx(active && 'bg-gray-100', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                  <Menu.Item>
                    <a href="#" onClick={logout} className="block px-4 py-2 text-sm text-gray-700">
                      Sign out
                    </a>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </nav>

      {/* mobile nav */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition east-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-20 pb-4">
                <div className="mt-3 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <div key={item.section}>
                        <h3 className="text-subtitle/75 font-bold text-sm">{item.section}</h3>
                        {item.children.map((link) => (
                          <NavLink
                            key={link.name}
                            to={link.href}
                            className={({ isActive }) =>
                              clsx(
                                isActive
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                              )
                            }
                            onClick={() => setSidebarOpen(false)}
                          >
                            {({ isActive }) => (
                              <>
                                <link.icon
                                  className={clsx(
                                    isActive ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                    'mr-4 flex-shrink-0 h-6 w-6'
                                  )}
                                  aria-hidden="true"
                                />
                                {link.name}
                              </>
                            )}
                          </NavLink>
                        ))}
                      </div>
                    ))}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* desktop sidebar */}
      <aside className="hidden pt-20 w-60 text-white bg-white fixed inset-y-0 overflow-x-hidden overflow-y-auto md:block">
        <div className="mt-3 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1 px-2 pb-4">
            {navigation.map((item) => (
              <div key={item.section}>
                <h3 className="text-subtitle/75 font-bold text-sm">{item.section}</h3>
                {item.children.map((link) => (
                  <NavLink
                    key={link.name}
                    to={link.href}
                    className={({ isActive }) =>
                      clsx(
                        isActive ? 'bg-lightblue text-[#0F799A]' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                      )
                    }
                    end={link.href === '/' ? true : false}
                  >
                    {({ isActive }) => (
                      <>
                        <link.icon
                          className={clsx(
                            isActive ? 'text-[#0F799A]' : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {link.name}
                      </>
                    )}
                  </NavLink>
                ))}
              </div>
            ))}
          </nav>
        </div>
      </aside>

      {/* main content */}
      <main className="pt-20 md:pl-60 h-full">
        <div className="p-4 bg-lightblue min-h-full flex flex-col">
          <Outlet />
        </div>
      </main>
    </>
  );
}

export default Layout;
