import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface IPlanBase {
  name: string;
  sku: string;
  desc: string;
  options: string[];
  button: string;
  link: string;
  isOutsideLink: boolean;
}
interface IPlanNumberPrice extends IPlanBase {
  monthPrice: number;
  annualPrice: number;
  stringPrice?: never;
}
interface IPlanStringPrice extends IPlanBase {
  monthPrice?: never;
  annualPrice?: never;
  stringPrice: string;
}
// IPlan = IPlanBase + ( IPlanNumberPrice or IPlanStringPrice )
type IPlan = IPlanNumberPrice | IPlanStringPrice;

const plans: IPlan[] = [
  {
    name: 'Basic',
    sku: 'basic',
    stringPrice: 'Free!',
    desc: 'For the everyday entrepreneur',
    options: [
      'Post 1 Job',
      'Job Expires after 14 days',
      'Max 10 Matches',
      'Good news for you is you don’t need many matches to find your next team member!',
    ],

    button: 'Continue',
    link: '/onboard/billing',
    isOutsideLink: false,
  },
  {
    name: 'Business',
    sku: 'business',
    monthPrice: 99,
    annualPrice: 999,
    desc: 'For small businesses',
    options: [
      '2 Jobs Per Month',
      '3 Licenses Max',
      '2 Profiles / License',
      'Verified Employer Badge!',
      'Match Sliders!',
    ],

    button: 'Try 14 days for free',
    link: '/onboard/billing',
    isOutsideLink: false,
  },
  {
    name: 'Enterprise',
    sku: 'enterprise',
    stringPrice: 'Custom',
    desc: 'For large corporations',
    options: [
      'Unlimited Jobs Per Month!',
      'As Many Profiles As You Need!',
      'Unlimted Matches Per Job!',
      'Verifed Employer Badge!',
      'Match Sliders!',
    ],

    button: 'Contact Us',
    link: 'https://www.prosperjobs.co/contact',
    isOutsideLink: true,
  },
];

//Plans: What's located on each card
function Plans() {
  const [isMonthly, setIsMonthly] = useState(true);

  const Plan = ({ plan }: { plan: IPlan }) => (
    <div className="flex-1 rounded flex flex-col justify-between px-8 py-4 m-0 min-w-[275px] max-w-xs leading-normal bg-white">
      <p className="break-words">
        <div className="text-xl py-2">{plan.name}</div>
        <br />
        <span className="text-4xl font-semibold">
          {plan.stringPrice !== undefined ? plan.stringPrice : isMonthly ? plan.monthPrice : plan.annualPrice}
        </span>
        {!plan.stringPrice && <span className="text-xl text-gray-400">/{isMonthly ? 'month' : 'year'}</span>}
        <br />
        <div className="text-sm py-2">{plan.desc}</div>
        <div className="flex flex-col py-2 w-full">
          {plan.options.map((value, index) => (
            <div className="flex-row relative py-2" key={index}>
              <div className="pointer-events-none absolute left-0 flex items-center">
                <CheckCircleIcon className="h-6 w-6 stroke-white fill-gray-400" />
              </div>
              <div className="text-sm pl-8">{value}</div>
            </div>
          ))}
        </div>
      </p>
      <div className="w-full mt-5">
        {plan.isOutsideLink ? (
          <a href={plan.link}>
            <button
              type="button"
              className="border border-primary rounded-lg p-2 text-primary bg-white w-full font-semibold transition ease-in-out hover:bg-primary hover:text-white"
            >
              {plan.button}
            </button>
          </a>
        ) : (
          <Link to={plan.link} state={{ plan: plan.sku, monthly: isMonthly }}>
            <button
              type="button"
              className="border border-primary rounded-lg p-2 text-primary bg-white w-full font-semibold transition ease-in-out hover:bg-primary hover:text-white"
            >
              {plan.button}
            </button>
          </Link>
        )}
      </div>
    </div>
  );

  return (
    <section>
      <h1 className="text-center tracking-wider">
        <Link
          to="/onboard/company"
          className="flex justify-center text-primary text-base uppercase font-semibold cursor-pointer"
        >
          <ChevronLeftIcon className="h-6" />
          START WITH 14 DAYS FREE TRIAL
        </Link>
        <br />
        <span className="text-header text-4xl font-semibold">Prosper AI Pricing</span>
        <br />
        <span className="text-gray-600 text-sm font-semibold">Select the best plan for your needs</span>
        <div className="flex justify-center mt-5 -mb-5">
          <label
            className={clsx(
              'px-4 py-2 rounded-l-xl border-r-0 cursor-pointer',
              isMonthly ? 'bg-primary text-white' : 'border border-subtitle/20 text-subtitle'
            )}
          >
            Monthly
            <input
              className="hidden"
              type="radio"
              name="billingOption"
              onClick={() => setIsMonthly(true)}
              value="monthly"
              defaultChecked={isMonthly}
            />
          </label>
          <label
            className={clsx(
              'px-4 py-2 rounded-r-xl border-l-0 cursor-pointer',
              !isMonthly ? 'bg-primary text-white' : 'border border-subtitle/20 text-subtitle'
            )}
          >
            Annually
            <input
              className="hidden"
              type="radio"
              name="billingOption"
              onClick={() => setIsMonthly(false)}
              value="annually"
              defaultChecked={!isMonthly}
            />
          </label>
        </div>
      </h1>
      <div className="px-28 pb-10 mt-10 flex flex-wrap items-stretch w-full gap-4 justify-center">
        {plans.map((value, index) => (
          <Plan key={index} plan={value} />
        ))}
      </div>
    </section>
  );
}

export default Plans;
